@import "~@angular/material/theming";
@include mat-core();
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

$md-trea: (
  50: #e0e0e0,
  100: #b3b3b3,
  200: #808080,
  300: #4d4d4d,
  400: #262626,
  500: #0082CC,
  600: #0082CC,
  700: #0082CC,
  800: #0082CC,
  900: #0082CC,
  A100: #a6a6a6,
  A200: #8c8c8c,
  A400: #737373,
  A700: #666666,
  contrast: (
    50: #0082CC,
    100: #0082CC,
    200: #0082CC,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #0082CC,
    A200: #0082CC,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

// Define the default theme (same as the example above).
$trea-primary: mat-palette($md-trea);
$trea-accent: mat-palette($md-trea, A200, A100, A400);
$trea-theme: mat-light-theme($trea-primary, $trea-accent);

// Include the default theme styles.
@include angular-material-theme($trea-theme);

.material-icons.color_green { color: #8cc63f ;}
