/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';

html,
body {
	height: 100%;
}

body {
	margin: 0;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.new-login {
	background: #d4eaf9;
}

mat-paginator {
	background-color: white;
	color: black !important;
}

.mat-cell,
.mat-header-cell {
	color: gray;
}

/* override material paginator*/
.mat-paginator-page-size {
	display: none !important;
}
.mat-paginator .mat-paginator-container {
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
}
.mat-paginator .mat-paginator-range-label {
	order: 2;
}

.mat-paginator .mat-paginator-navigation-previous {
	order: 1;
}
.mat-paginator .mat-paginator-navigation-next {
	order: 3;
}

.timepicker-overlay {
	z-index: 1100 !important;
}

.timepicker-backdrop-overlay {
	z-index: 1100 !important;
}

.timepicker__header {
	background-color: #0082cc !important;
}

.clock-face__number > span.active {
	background-color: #0082cc !important;
}
.clock-face__button {
	background-color: #0082cc !important;
}

.clock-face__clock-hand {
	background-color: #0082cc !important;
}

.clock-face__clock-hand_minute:before {
	border: 4px solid #0082cc !important;
}

button:not(.timepicker-dial__item) {
	color: #0082cc !important ;
}

.custom-dialog-container .mat-dialog-container {
	padding: 0 !important;
}

.mat-select-disabled .mat-select-value {
	color: black;
}

.mat-select-arrow {
	border-left: 15px solid transparent !important;
	border-right: none !important;
	border-top: 15px solid transparent !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
	color: #0082cc !important;
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
	color: #0082cc !important;
}
mat-input {
	justify-content: center;
	align-items: center;
	display: flex;
}
.page {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.ngx-pagination .pagination-previous {
	display: none;
}
.ngx-pagination .pagination-next {
	display: none;
}
.ngx-pagination .current {
	background: #0082cc;
	border: transparent !important;
	border-radius: 20px !important;
}

.ngx-pagination {
	padding-left: 0px !important;
	margin: 1rem;
	display: flex;
	flex-direction: row;
}

.ngx-pagination a:hover {
	border-radius: 20px !important;
	border: transparent !important;
	text-decoration: none;
}
.headerButtons2 {
	display: flex;
	justify-content: center;
}
.headerButtons {
	padding-right: 1vw;
}
mat-icon.icon {
	height: 14px;
	width: 14px;
}
